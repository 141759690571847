* {
  transition: 300ms;
}

.wrapper {
  background: #0A090B;
}

.wrapperInner {
  display: grid;
  grid-template-rows: 80px 1fr;
  grid-template-columns: 1fr;
}

.header {
  grid-column: 1/2;
  grid-row: 1/2;
  position: sticky;
  top: 0px;
}

.main {
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  grid-gap: 24px;
  padding: 24px;
}

.mainInner {
  max-width: 1392px;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr;
  margin: auto;
}

.videoPlayerArea {
  max-width: 1018px;
}

.likesButton button {
  outline: 0px;
  border: 0px;
  background: linear-gradient(90deg,
      rgba(255, 79, 153, 0.12) 0%,
      rgba(255, 79, 153, 0) 100%),
    #050506;
  border: 1px solid rgba(255, 79, 153, 0.3);
  box-sizing: border-box;
  border-radius: 8px;

  padding: 12px 16px;

  color: rgba(255, 79, 153, 0.8);

  display: flex;
  align-items: center;
  grid-gap: 17px;
}

.twitterButton button {
  background: linear-gradient(90deg,
      rgba(100, 153, 255, 0.12) 0%,
      rgba(100, 153, 255, 0) 100%),
    #050506;
  border: 1px solid rgba(100, 153, 255, 0.4);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px;

  color: rgba(125, 169, 255, 0.8);

  display: flex;
  align-items: center;
  grid-gap: 17px;
}

.copyLink button {
  background: linear-gradient(90deg,
      rgba(215, 139, 255, 0.12) 0%,
      rgba(215, 139, 255, 0) 100%),
    #050506;
  border: 1px solid rgba(215, 139, 255, 0.3);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px;

  color: rgba(210, 128, 255, 0.8);

  display: flex;
  align-items: center;
  grid-gap: 17px;
}

.likesButton button,
.twitterButton button,
.copyLink button {
  cursor: pointer;
}

.likesButton button span,
.twitterButton button span,
.copyLink button span {
  display: flex;
  align-items: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.tag p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #62bfef;
}

.title h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
}

.videoActions {
  margin-top: 40px;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  grid-template-rows: 1fr;
  grid-gap: 16px;
}

.videoActions .left {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.videoActions .right {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  grid-gap: 24px;
}

.videoPlayer {
  width: 800px;
  height: 450px;
  border-radius: 6px;
  overflow: hidden;

  box-shadow: 0px 24px 64px -40px #798dff8a;

}

@media screen and (max-width: 800px) {
  .videoPlayer {
    width: 90vw;
    height: calc(90vw/1.6);
    margin: 0 auto;
  }
}

.videoPlayer div {
  width: 100%;
  height: 100%;
}

.videoPlayer div iframe {
  width: 100%;
  height: 100%;
}

.rightThumbnailsCont {
  max-width: 350px;
}

.sectionThumb {
  width: 100%;
  height: 180px;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: 300ms;
}

.listOfVideoThumbnails {
  height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tabs {
  background: #0a090b;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
}

.singleTab {
  height: 48px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  cursor: pointer;
}

.singleTab span {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #eaeaea;
}

.selectedTab {
  background: linear-gradient(90deg,
      rgba(72, 211, 255, 0.16) 0%,
      rgba(72, 211, 255, 0) 100%),
    #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
}

.normalCard,
.expandedCard {
  background: #0e0e11;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  border-radius: 6px;
  transition: 300ms;
  cursor: pointer;
  margin-top: 16px;
  transition: 300ms;
  min-width: 346px;
}

.normalCard:hover,
.expandedCard:hover {
  filter: brightness(1.2);
  transform: translateY(-2px)
}

.cardDisabled {
  filter: saturate(0);
  pointer-events: none;
}

.cardDisabled h3 {
  opacity: 0.7;
  text-decoration: line-through;
}


.normalCard h3,
.expandedCard h3,
.expandedCard p,
.normalCard p {
  margin: 0;
}

.normalCard .thumbnail,
.expandedCard .thumbnail {
  height: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 6px 6px 0px 0px;
  transition: 300ms;
}

.expandedCard .thumbnail {
  height: 60px;
}

.titleAndDate {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 0px 0px 6px 6px;
}

.titleAndDate .title h3 a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  text-decoration: none;
}

.titleAndDate .date p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  color: #9d9d9d;
}

.copyLink button:hover {
  filter: brightness(1.2)
}

.copyLink button:active {
  filter: brightness(1.5)
}



@media screen and (max-width: 870px) {
  .wrapperInner {
    grid-template-rows: 48px 1fr;
  }

  .sectionThumb {
    display: none;
  }

  .main {
    flex-flow: column nowrap;
    padding: 10px;
  }

  .rightThumbnailsCont {
    max-width: none;
  }

  .title h1 {
    font-size: 14px;
  }

  .tag p {
    font-size: 14px;
  }

  .videoActions {
    grid-template-rows: 1fr max-content;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 24px;
  }

  .likesButton button,
  .twitterButton button,
  .copyLink button {
    width: 100%;
  }

  .copyLink {
    grid-column: 1/3;
  }

  .likesButton button,
  .twitterButton button,
  .copyLink button {
    justify-content: center;
  }

  .normalCard,
  .expandedCard {
    min-width: unset;
  }
}
.wrapper {
  height: 80px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(48px);

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-self: center;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0px 64px;
  position: fixed;
  top: 0px;
  z-index: 2;
}

.left {}

.right {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

}

.right .name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 275;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  padding: 0px 12px;
}

.right .logoutBtn {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #62bfef;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0px 12px;
  cursor: pointer;
}

.logoCont {
  display: block;
  text-decoration: none;
}




@media screen and (max-width: 600px) {
  .wrapper {
    height: 48px;
    padding: 0px 10px;
  }

  .logoutBtn img,
  .logoCont img {
    max-width: 18px;
  }


}
.wrapper {
  background: radial-gradient(62.97% 150.89% at 100% 100%,
      rgba(255, 0, 245, 0.1) 0%,
      rgba(255, 0, 245, 0) 100%),
    radial-gradient(71.72% 276.12% at 0% 0%,
      rgba(0, 163, 255, 0.1) 0%,
      rgba(0, 240, 255, 0) 100%),
    #0a090b;
  /* Border/Border 10 */

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 42px 30px 0px 30px;

  max-width: 1392px;
  margin: auto;
  position: relative;
}

.s2 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  grid-gap: 10px;
}

.navlinksCont {
  margin-top: 88px;
}

.navlinks {
  max-width: 223px;
}

.navlinks ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.navlinks ul li {
  margin: 0px;
  margin-bottom: 15px;
}

.navlinks ul li,
.navlinks ul li a {
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #9d9d9d;
}

.navlinksH {
  margin-bottom: 32px;
}

.navlinksH span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
}

.socialIcons ul {
  max-width: 150px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.s3 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 65px;
  padding: 30px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.s3 .navlinks {
  max-width: none;
}

.s3 .navlinks ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  grid-gap: 50px;
}

.copyRight span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #9d9d9d;
  margin-right: 200px;
}

.arrowUp {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  grid-gap: 24px;
  margin-top: 60px;
}

.arrowUp .txt {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #9d9d9d;
}

.arrowUp .arrowIcon {
  background: #1d1c21;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 17px 14px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


@media screen and (max-width: 870px) {

  .wrapper {
    padding: 30px 10px;
  }

  .s3 {
    flex-flow: column-reverse wrap;
    align-items: center;
    grid-gap: 30px;
  }

  .s3 .navlinks {
    width: 100%;
  }

  .s3 .navlinks ul {
    grid-gap: 0px;
    width: 100%;
    justify-content: space-between;
  }

  .copyRight span {
    margin-right: 0px;
  }

  .arrowUp .txt {
    display: none;
  }
}


@media screen and (max-width: 600px) {

  .s3 .navlinks ul {
    grid-gap: 0px;
    width: 100%;
    flex-flow: column nowrap;
  }

  .s2 {
    flex-flow: column nowrap;
  }
}
:root {
  --primary-color: #2DB75B;
  --light-green: #E1F6E8;
  --primary-font-family: 'Raleway', sans-serif;
  --secondary-font-family: 'Lora', serif;
  ;
}


body {
  background-color: #0A090B;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Raleway', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::placeholder {
  font-family: 'Raleway', sans-serif;
}

* {
  box-sizing: border-box;
}


p {
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
}

h2 {
  font-size: 36px;
  font-family: 'Raleway', sans-serif;
}

button,
span,
input {
  font-family: 'Raleway', sans-serif;
}


.overlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}


@media screen and (min-width: 480px) {
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: rgba(30, 30, 30, 1);
    margin-block: 3px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgba(50, 50, 50, 1);
    border: 3px solid rgba(30, 30, 30, 1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(70, 70, 70, 1);
  }
}


@media screen and (max-width: 963px) {}

@media screen and (max-width: 600px) {}